import React from 'react';
import {
  ColorDataTableCode,
  Paragraph,
  PlatformTab,
  Section,
} from '../../../../components';

const WebTab = ({ data }) => {
  return (
    <PlatformTab>
      <Paragraph>Use the values below.</Paragraph>

      <Section title="Light Environment">
        <ColorDataTableCode
          env="light"
          colorsType="light"
          data={data}
          platform="web"
        />
      </Section>

      <Section title="Dark Environment">
        <ColorDataTableCode
          env="dark"
          colorsType="dark"
          data={data}
          platform="web"
        />
      </Section>

      <Section title="Base">
        <ColorDataTableCode env="light" data={data} platform="web" />
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
